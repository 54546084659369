import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import HomePage from './components/pages/HomePage';
import AboutPage from './components/pages/AboutPage';
import PageOutlet from './components/common/PageOutlet';

function App() {
  return (
    <div className="App">
      <Routes>
          <Route path="/" element={<PageOutlet />}>
            <Route path="" element={<HomePage />} />
            <Route path="about" element={<AboutPage />} />
          </Route>
      </Routes>

    </div>
  );
}

export default App;
