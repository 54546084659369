import React from 'react'
import { Container } from 'react-bootstrap'
import TopNavBar from './TopNavBar'
import { Outlet } from 'react-router-dom'

function PageOutlet() {
  return (
    <div> 
        <TopNavBar />
    <Container>
        <Outlet/> 
    </Container>
        
    <br/></div>
  )
}

export default PageOutlet