import React, { useRef, useState } from 'react'
import { Alert, Button, Col, FloatingLabel, Form, Row, Spinner } from 'react-bootstrap'
import RadioListWidget from './RadioListWidget'
import { postTip } from '../../services/tips/tip-services';
import ModalLoader from '../widgets/ModalLoader';
import { formatHttpErrorMessage } from '../utils/helper_functions';

function HomePage() {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const inputReference = useRef(null);
    const [formData, setFormData] = useState({
        "keyword":"",
        "phone":""
    })
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        inputReference.current.focus();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        await postTip(formData).then(response => {
            setMessage("Tip successfully posted!")
        }).catch(error => {
           let msg = formatHttpErrorMessage(error)
           setErrorMessage(msg)
           setLoading(false)
        })
                
    }

    const handleSuccess = () => {
      setMessage("Thank you for your payment. We have received it successfully. You will receive an sms shortly")
      setLoading(false)
    }
  return (
    <div>
      <Row className="mt-3">
        <Col md={12}>
          <h4>GET Your Tips</h4>
          <p>Ready to win big? Get personalized betting tips that will boost your chances of success! Fill out our form today and start your winning streak!</p>
        </Col>
      </Row>

      <Row className="g-3">
        <Col md={6}>
            {loading && <><ModalLoader phonenumber={formData.phone} show={loading} onHide={() => setLoading(false)} onSuccess={handleSuccess} /></>}
            {errorMessage && <Alert variant='danger'>{errorMessage}</Alert>}
            {message && <Alert variant='success'>{message}</Alert>}
            <Form className="mt-5" onSubmit={handleSubmit}>
              <Row className="g-1">
                <Col xs={12}>
                  <RadioListWidget
                    name="keyword"
                    value="1"
                    title="Normal"
                    subtitle="Dial *210*71*1#"
                    trailing="2,500"
                    onChange={handleChange}
                  />
                </Col>
                <Col xs={12}>
                  <RadioListWidget
                    name="keyword"
                    value="2"
                    title="VIP"
                    subtitle="Dial *210*71*2#"
                    trailing="5,000"
                    onChange={handleChange}
                  />
                </Col>
                <Col xs={12}>
                  <RadioListWidget
                    name="keyword"
                    value="3"
                    title="VVIP"
                    subtitle="Dial *210*71*3#"
                    trailing="10000"
                    onChange={handleChange}
                  />
                </Col>
                <Col xs={12}>
                  <RadioListWidget
                    name="keyword"
                    value="4"
                    title="Corporate"
                    subtitle="Dial *210*71*4#"
                    trailing="20000"
                    onChange={handleChange}
                  />
                </Col>
               
              </Row>
              

              <Row className="mt-2">
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type="number"
                      name="phone"
                      placeholder="Your Mobile Money phone number"
                      onChange={handleChange}
                      ref={inputReference}
                      
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col className="d-grid gap-2">
                  <Button
                    variant="success"
                    type="submit"
                    disabled={formData.phoneNumber == ""}
                  >
                    PAY AND GET TIPS
                  </Button>
                </Col>
              </Row>
            </Form>
          
        </Col>
        <Col md={8}></Col>
      </Row>
    </div>
  );
}

export default HomePage