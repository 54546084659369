import React, { useEffect, useState } from 'react'
import { Alert, Button, Modal, Spinner } from 'react-bootstrap'
import { fetchPaymentState } from '../../services/tips/tip-services';
import { formatHttpErrorMessage } from '../utils/helper_functions';

function ModalLoader(props) {
    const [message, setMessage] = useState();
    const [status, setStatus] = useState();
    const MINUTE_MS = 60000;

    useEffect(() => {
    const interval = setInterval(() => {
        console.log('Checking for payment status ' + new Date());
        checkStatus();
    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, []);

    const checkStatus = async () => {
        setStatus(true);
        await fetchPaymentState(props.phonenumber).then(response => {
            if(response.data.status == 'Success'){
                props.onSuccess();
            }else if(response.data.status == 'Failed'){
                props.onHide();
            }
        }).catch(error => {
            let msg = formatHttpErrorMessage(error)
            setMessage(msg);
        })
        setStatus(false);
    }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Body>
        <center>
            {message && <Alert variant='danger'>{message} <br/><Button onClick={() => props.onHide()}>Close</Button></Alert>}
            <h4>Loading...</h4>
        <Spinner />
        <p>
            Please approve the mobile money request to Confirm the Prediction.
        </p>
        </center>
        {status && <>Checking...</>}
      </Modal.Body>
    </Modal>
  )
}

export default ModalLoader