export function formatHttpErrorMessage(error) {
  if (!error.response) {
    return "Could not reach the server! Please check your Internet Connection.";
  }
  if (error.response.status === 400) {
    let result = "";
    Object.keys(error.response.data).forEach((i) => {
        let field_name = error.response.data[i];
        
        if (typeof field_name === 'object' && field_name !== null && !Array.isArray(field_name)) {
          Object.keys(field_name).forEach((j) => {
            result += `${i}[${j}] ${error.response.data[i][j]},\n`;
          });
        }else{
          result += `[${i}] ${error.response.data[i]},\n`;
        }
        
    });
    return result;
  }else if(error.response.status === 401){
    window.location.replace("/", false);
    return "401: Invalid login tokens were provided.";
  } else if (error.response.status === 403) {
    return "403: You do not have permission to perform this action.";
  }else if(error.response.status == 404){
     return "404: Resource not Found!";
  } else if (error.response.status === 500) {
    return "We experience an difficuly in processing you request with error 500.";
  } else {
    return "Unknown Error occurred. Please contact the system administrator for assistance";
  }
}

export function formatShortDate(date) {
  const options = {
    year: "numeric", month: "short", day: "numeric",hour: 'numeric',
    minute: 'numeric',
    hour12: true
  };
    const intDateFormat = new Intl.DateTimeFormat("en-GB", options);
    if(date){
      return intDateFormat.format(new Date(date));
    }
    return;
}

export function formatDate(date) {
  const options = {
    year: "numeric", month: "short", day: "numeric",
    hour12: true
  };
    const intDateFormat = new Intl.DateTimeFormat("en-GB", options);
    if(date){
      return intDateFormat.format(new Date(date));
    }
    return;
}

export const getInvoiceItemsTotal = (items) => {
  const sum = items.reduce((accumulator, object) => {
    return accumulator + (object.price * object.qty);
  }, 0);
  return sum
}

export function formatNumber(number){
  return parseInt(number).toLocaleString("en-GB", {maximumFractionDigits:2})
}

export function isMember(items, container) {
   return container.find(item => items?.includes(item))
}