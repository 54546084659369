import React from 'react'
import { Container, Nav, Navbar, Offcanvas } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'

function TopNavBar() {
  return (
    <>
    <Navbar style={{backgroundColor:'#9E2323'}} variant="dark" expand="lg">
        <Container>
          <Navbar.Brand href="#home">
            Max Tips
          </Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-1`} />
          <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-1`}
              aria-labelledby={`offcanvasNavbarLabel-expand-1`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-1`}>
                  MENU
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
              <Nav className="me-auto">
              <Nav.Link as={NavLink} to="/">
                Home
              </Nav.Link>
              <Nav.Link as={NavLink} to="/about">
                About
              </Nav.Link>
              </Nav>
            
            </Offcanvas.Body>
            </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  )
}

export default TopNavBar