import React from 'react'
import { Card, Col, Form, Row } from 'react-bootstrap'

function RadioListWidget(props) {

    const handleOnClick = (e) => {
        alert("You have selected " + props.title)
    }
  return (
    <>
        
        <Card><Form.Check.Label>
                    <Row>
                        <Col xs={2} className='text-center pt-3'>
                            <Form.Check type="radio" name={props.name} id={`default-${props.name}`} value={props.value} onChange={props.onChange} />
                        </Col>
                        <Col xs={6} className='text-start'>
                            <p className='text-left'>
                                <strong>{props.title}</strong><br/>
                                <small>{props.subtitle}</small>
                            </p>
                        </Col>
                        <Col xs={4}>
                            <div className='text-end p-3' style={{fontSize:'20px'}}>
                                {props.trailing}
                            </div>
                        </Col>
                    </Row></Form.Check.Label>
            </Card>
            
    </>
  )
}

export default RadioListWidget