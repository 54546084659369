import axios from "axios";
// const baseURL = "http://127.0.0.1:8000/";
//const baseURL = "https://tip3.kasubisystems.com/"
const baseURL = "https://kasubisystems.com/"
// const baseURL = "/api/";
//const baseURL=process.env.REACT_APP_BASE_URL
// const accessToken = localStorage.getItem("access_token");

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";
const axiosAPI = axios.create({
  baseURL: baseURL,
  timeout: 5000,
  // headers: {
  //   Authorization: accessToken ? "Bearer " + accessToken : null,
    "Content-Type": "application/json",
    accept: "application/json"
  // }
});

export async function postTip(payload) {
    const response = await axiosAPI.post("ussd/webtips/", payload);
    return response;
  }

export async function fetchPaymentState(number) {
  const response = await axiosAPI.get(`/wallet/payment/check/${number}`);
  return response;
}
